<template>

<div style="text-align:center;">
    <h4 class="text-dark">Check price and place your order</h4>
</div>
           
<div class="row" style="border-bottom: solid 1px lightgrey; padding-bottom: 10px;">
                    <div class="col-md-4 col-4" style="border-right: solid 1px lightgrey;">
                        <router-link to="/" class="btn theme-btn-3">Car Tyres</router-link>
                    </div>
                    <div class="col-md-4 col-4" style="border-right: solid 1px lightgrey;">
                        <router-link to="/batteries" class="btn theme-btn-3">Car Batteries</router-link>
                    </div>
                    <div class="col-md-4 col-4">
                        <router-link to="/car-service" class="btn theme-btn-3">Book Your Car Service</router-link>
                    </div>
                </div>


</template>