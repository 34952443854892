import Vuex from 'vuex'


export default new Vuex.Store({
  state: {
    api_url: 'https://precise.sizemorechauffers.com/',
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})