<template>

<section>
  <div class="container shadow">

    <div class="row">
      <div class="col-md-3" style="padding-top:15px; border-right: solid 1px #EAE9E9;">
        <div>
          <img src="assets/images/logo-2.png" style="height: 120px;">
        </div>
      </div>

      <div class="col-md-2" style="padding-top:10px; border-right: solid 1px #EAE9E9;">
        Car tyres<br>
        Batteries<br>
        Car service<br>
        Suspension<br>
        Bushes

      </div>

      <div class="col-md-4" style="padding-top:37px; border-right: solid 1px #EAE9E9;">
        <div>
          Call us on : <a href="tel:0707450147" style="text-decoration: none; color:#000;">0707450147</a> <br />
          Email us at : <a href="mailto:precisebattery@gmail.com" style="text-decoration: none; color:#000;">precisebattery@gmail.com</a><br> <a href="mailto:clientsupport@preciseautoservice.co.ke" style="text-decoration: none; color:#000;">clientsupport@preciseautoservice.co.ke</a><br />
          <span>Ngong road branch,Karen</span>

        </div>
      </div>

      <div class="col-md-3" style="padding-top:10px;">
        <div>
          <img src="/assets/images/mpesa.png" style="height: 50px;">
          <h6>Lipa na MPESA</h6>
          <h6>MPESA Paybill number :  880100 </h6>
          <h6>Acc number : 4719460013</h6>
        </div>
      </div>
      
    </div>
    
  </div>
</section>

<section style="padding-top:30px; padding-bottom:20px;">
  <div class="container">
 
    <div>
      <div style="text-align:center;">
        <h4>OUR BRANDS</h4>
      </div>
      <div class="brands">
      <img src="/assets/images/brands/bfgoodrich-tires-vector-logo.png" class="image shadow rounded">
      <img src="/assets/images/brands/Bridgestone-Logo.png" class="image shadow rounded">
      <img src="/assets/images/brands/logo-2.jpeg" class="image shadow rounded">
      <img src="/assets/images/brands/Hankook-Logo-1999.png" class="image shadow rounded">
      <img src="/assets/images/brands/falken-tire-vector-logo.png" class="image shadow rounded">
      <img src="/assets/images/brands/Maxxis-Logo-old.png" class="image shadow rounded">
      <img src="/assets/images/brands/yokohama-tires4177.jpeg" class="image shadow rounded">
       | 
       <img src="/assets/images/brands/logo-7.jpeg" class="image shadow rounded">
       <img src="/assets/images/brands/logo-3.jpeg" class="image shadow rounded">
      <img src="/assets/images/brands/logo-4.bmp" class="image shadow rounded">
      <img src="/assets/images/brands/logo-6.jpeg" class="image shadow rounded">
       <img src="/assets/images/brands/yama.jpeg" class="image shadow rounded">

       </div>
    </div>
    
  </div>
</section>


</template>
    

    
<script>
export default{

    name : 'Header',

    data () {
      return {
        current_date : ''
      }
    },

    methods : {
      get_time(){
const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear()
let currentDate = `${day}.${month}.${year}`;
this.current_date = currentDate;
}
},

created (){
  this.get_time()
}
}
</script>