import {createRouter,createWebHistory} from 'vue-router'

import Home from '../components/Home'
import Brands from '../components/Brands'
import Batteries from '../components/Batteries'
import CarService from '../components/CarService'



const routes = [
    {
        path : '/',
        name : 'home',
        component : Home
    },

  

    {
        path : '/brands',
        name : 'brands',
        component : Brands
    },

    {
        path : '/batteries',
        name : 'batteries',
        component : Batteries
    },

    {
        path : '/car-service',
        name : 'car-service',
        component : CarService
    },

]


const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes: routes
})


export default router