<template>
    <Header />
        <section style="padding-top: 0px;">
    
    
            <div class="row" style="background-color: #1a1a1a;">
                    <div class="col-md-12">
                        <div style="text-align: center; padding-top: 30px; padding-bottom: 30px;">
                           
                        <h2 style="color: lightgrey;">Have your car serviced</h2>
                    </div>
                    </div>
                
                </div>
    
      
        </section>
    
    
       <BrandsSection />
    
       <Footer />
    
    </template>
    
    <script>
    import Header from './Header'
    import BrandsSection from './BrandsSection'
    import Footer from './Footer'
    export default{
        name : 'Success',
        components : {Header,BrandsSection,Footer},
        data () {
            return {
                
              
            }
        },
    
        methods: {
        
        },
        created(){
        window.scrollTo('0','0')
    }
    }
    </script>