<template>

<section style="background-color: #F6F6F6; padding-top: 50px; padding-bottom: 50px;">
    
    <div class="container">
        <div class="row">
            <div class="col-md-12" style="text-align: center;">
                <h1 style="color: #800;">Our Top Brands</h1>
            </div>


            <div class="col-md-2" style="text-align: center;">
                <img src="assets/images/BMW.png" style="width:100%;" class="shadow-sm p-3 mb-5 bg-body rounded">
            </div>
            <div class="col-md-2" style="text-align: center;">
                <img src="assets/images/BMW.png" style="width:100%;" class="shadow-sm p-3 mb-5 bg-body rounded">
            </div>


        </div>
    </div>

</section>

</template>

<script>

export default{
    name : 'Brands'
}

</script>