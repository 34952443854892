<template>
    <Header />
        <section style="padding-top: 0px;">
            
            <div class="container">
    
            <div class="row" style="padding-top: 40px; padding-bottom: 40px;">
    
                <div class="col-md-8 shadow-lg p-3 mb-0 bg-body rounded">
             
                    <Menu />
    
                    <form @submit.prevent="confirm_service()">
        <div class="row">

            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Car make" v-model="car_make">
            </div>

              <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Car model" v-model="car_model">
            </div>


            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Enter chasis number" v-model="chasis_number">
            </div>
    
          
    
            
    
            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Year of manufacture" v-model="year_of_manufacture">
            </div>


            <div class="col-md-6">
                <label>Plate number</label>
                <input type="text" class="form-control input-design" placeholder="Enter plate number" v-model="plate_number">
            </div>

            <div class="col-md-6">
                <label>Service date</label>
                <input type="date" class="form-control input-design" placeholder="Enter service date" v-model="service_date">
            </div>

            <div class="col-md-6">
            <label>Service time</label>
            <input type="time" class="form-control input-design" placeholder="Fitting time" v-model="service_time">
        </div>

            <div class="col-md-6">
                <label>Select the service type below</label>

                <select class="form-select input-design" v-model="service">
                    <option selected></option>
                    <option>Brake adjustment</option>
                    <option>Brake pads</option>
                    <option>Bushes,engine and gearbox mountings,stablizer links,cv joints,boil joints,racks</option>
                    <option>Paint work and accident repair</option>
                    <option>Rim straightening</option>
                    <option>Wiper blades</option>
                    <option>Bulbs,front and tail light adjustment</option>
                    <option>Engine and gearbox repair</option>
                    <option>Wheel alignment</option>
                    <option>Wheel balancing</option>
                    <option>Air conditioner refill</option>
                    <option>Suspension(Shock absorbers,coil springs,etc)</option>
                    <option>5,000KM Engine Service</option>
                     <option>7,000KM Engine Service</option>
                      <option>10,000KM Engine Service</option>
                    
                </select>

               
            </div>

    
            <div class="col-md-6" style="padding-top: 20px;">
               
               <button class="btn theme-btn-2">Submit your order & get your qoutation</button>
            </div>
    
        </div>
    </form>
                  
      
                </div>
    
                <div class="col-md-4" style="background-image: url('assets/images/banner-1.jpeg'); background-size: cover; min-height: 300px;">
    
                </div>
        
    
    
            </div>
        </div>
        </section>
    
 
    
    
    <Footer />
    
    
    <!-- modal -->
    <div class="moda" v-if="show_modal">
    <div class="moda-content">
    <h3>Contact Information</h3>
    <form @submit.prevent="submit_service()">
        <div class="row">
            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="First name" v-model="first_name">
            </div>
    
            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Last name" v-model="last_name">
            </div>
    
            <div class="col-md-6">
                <input type="email" class="form-control input-design" placeholder="Email address" v-model="email">
            </div>
    
            <div class="col-md-6">
                <input type="text" class="form-control input-design" placeholder="Phone number" v-model="phone">
            </div>

        
    
            <div class="col-md-6" style="padding-top: 20px;">
               <button type="button" class="btn btn-danger" style="margin-right: 10px;" @click="show_modal=false">Close</button>
               <button class="btn theme-btn-2" :disabled="disabled">{{text}}</button>
            </div>
    
        </div>
    </form>
    </div>
    </div>
    
    </template>
    
    <script>
    import Header from './Header'
    import BrandsSection from './BrandsSection'
    import Footer from './Footer'
    import Menu from './Menu'
    import axios from 'axios'
    export default{
        name : 'Home',
        components : {Header,BrandsSection,Footer,Menu},
        data () {
            return {
               show_modal : false,
               first_name : '',
               last_name : '',
               email : '',
               phone : '',
               service_date : '',
               service_time : '',
               service : '',
               car_model : '',
               car_make : '',
               chasis_number : '',
               year_of_manufacture : '',
               plate_number : '',
               text : 'Submit',
               disabled : false

            }
        },
    
        methods: {
         
    
    
            async confirm_service(id){ 
                if(!this.chasis_number){
                    alert('Chasis number is required')
                    return
                }
                if(!this.car_model){
                    alert('Car model is required')
                    return
                }
                if(!this.car_make){
                    alert('Car make is required')
                    return
                }

                if(!this.year_of_manufacture){
                    alert('Year of manufacture is required')
                    return
                }

                if(!this.service){
                    alert('Service description is required')
                    return
                }

                if(!this.plate_number){
                    alert('Plate number is required')
                    return
                }

                this.show_modal = true
               
            },
    
            async submit_service(){
                if(!this.first_name){
                    alert('First name is required')
                    return
                }
    
                if(!this.last_name){
                    alert('Last name is required')
                    return
                }
    
                if(!this.email){
                    alert('Email is required')
                    return
                }
    
                if(!this.phone){
                    alert('Phone is required')
                    return
                }

                if(!this.service_date){
                alert('Date is required')
                return
            }

            if(!this.service_time){
                alert('Time is required')
                return
            }

            this.text = "Please wait..."
            this.disabled = true
    
                const res = await axios.post(this.$store.state.api_url+'api/submit-service',{
                    first_name : this.first_name,
                    last_name : this.last_name,
                    email : this.email,
                    phone : this.phone,
                    service_date : this.service_date,
                    service_time : this.service_time,
                    chasis_number : this.chasis_number,
                    car_model : this.car_model,
                    car_make : this.car_make,
                    year_of_manufacture : this.year_of_manufacture,
                    service : this.service,
                    plate_number : this.plate_number
                }).then(function(response){
                    return response.data
                }).catch(function(error){
                    console.log(error)
                })
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Your order has been submitted successfully',
      showConfirmButton: false,
      timer: 1500
    })
    
    this.show_modal = false
    this.first_name = ''
    this.last_name = ''
    this.email = ''
    this.phone = ''
    this.chasis_number = ''
    this.car_model = ''
    this.car_make = ''
    this.year_of_manufacture = ''
    this.service = ''
    this.service_date = ''
    this.service_time = ''
    this.plate_number = ''

    this.disabled = false
    this.text = 'Submit'
               
            },
    
    
        },
        created(){
            
        }
    }
    </script>
    
    
    <style scoped>
    
    </style>