<template>
<Header />
<section style="padding-top: 0px;">

<div class="container">


<div class="row" style="padding-top: 0px; padding-bottom: 40px;">

<div class="col-md-8 shadow-lg p-3 mb-0 bg-body rounded">

<Menu />

<form style="padding-top: 10px;" @submit.prevent="search_tyre()">
<div class="input-group mb-3">
<input type="search" class="form-control" placeholder="Enter tyre size" aria-label="Recipient's username" aria-describedby="button-addon2" list="sizes" v-model="size">
<button class="btn theme-btn-4" type="submit" id="button-addon2"> <i class="bi bi-search"></i> </button>
</div>
<datalist id="sizes">
<option v-for="tyre in tyres">{{ tyre.size }}</option>
</datalist>
</form>

<div>

<p class="text-danger">{{ message }}</p>

<div class="table-responsive" v-if="search_results.length">
	
	<table class="table">
	<thead>
		<tr> <th>Images</th> <th>Size</th><th>Pattern</th> <th>Brand</th> <th>Cost</th> <th>Action</th>  </tr>
	</thead>
	<tbody v-for="search_result in search_results">
		<tr> <td> <img class="shadow-lg rounded" style="height:40px;" :src="this.$store.state.api_url+'assets/tyres/'+search_result.image" v-if="search_result.image!=''"> </td> <td> <button class="btn btn-link" @click="confirm_purchase(search_result.id)"> {{search_result.size}} </button> </td> <td> {{search_result.description}} </td> <td> {{search_result.brand}} </td> <td> Ksh.{{search_result.cost}} </td> <td> <button class="btn btn-warning btn-sm" @click="confirm_purchase(search_result.id,search_result.size,search_result.description,search_result.brand,search_result.cost)">Order</button> </td> </tr>
	</tbody>
</table>

</div>


</div>


</div>

<div class="col-md-4" style="background-image: url('assets/images/banner-1.jpeg'); background-size: cover; min-height: 300px;">

</div>

</div>




</div>
</section>





<Footer />


<!-- modal -->
<div class="moda" v-if="show_modal">
<div class="moda-content">
<h3>Contact Information</h3>
<form @submit.prevent="confirm_tyre()">
<div class="row">
<div class="col-md-6">
<input type="text" class="form-control input-design" placeholder="First name" v-model="first_name">
</div>

<div class="col-md-6">
<input type="text" class="form-control input-design" placeholder="Last name" v-model="last_name">
</div>

<div class="col-md-6">
<input type="email" class="form-control input-design" placeholder="Email address" v-model="email">
</div>

<div class="col-md-6">
<input type="text" class="form-control input-design" placeholder="Phone number" v-model="phone">
</div>

<div class="col-md-12" style="padding-top: 10px;">
<p>Book your fitting date and time</p>
</div>

<div class="col-md-6">
<label>Fitting date</label>
<input type="date" class="form-control input-design" placeholder="Fitting date" v-model="fitting_date">
</div>

<div class="col-md-6">
<label>Fitting time</label>
<input type="time" class="form-control input-design" placeholder="Fitting time" v-model="fitting_time">
</div>

<div class="col-md-6">
<label>Quantity of tyres</label>
<input type="number" min="1" class="form-control input-design" v-model="qty">
</div>

<div class="col-md-12" style="padding-top: 20px;">
<button type="button" class="btn btn-danger" style="margin-right: 10px;" @click="show_modal=false">Close</button>
<button class="btn theme-btn-2" :disabled="disabled">{{text}}</button>
</div>

</div>
</form>
</div>
</div>

</template>

<script>
import Header from './Header'
import BrandsSection from './BrandsSection'
import Footer from './Footer'
import Menu from './Menu'
import axios from 'axios'
export default{
name : 'Home',
components : {Header,BrandsSection,Footer,Menu},
data () {
return {
size : '',
search_results : [],
message : '',
tyres : [],
show_modal : false,
first_name : '',
last_name : '',
email : '',
phone : '',
selected_tyre_id : '',
fitting_date : '',
fitting_time : '',
size : '',
pattern : '',
brand : '',
cost : '',
text : 'Submit',
disabled : false,
qty : 1
}
},

methods: {
async search_tyre(){
const res = await axios.post(this.$store.state.api_url+'api/search-tyre',{
size : this.size
}).then(function(response){
return response.data
}).catch(function(error){
console.log(error)
})

this.search_results = res
if(res.length==0){
this.message = 'Tyre size not in our records database'
}else{
this.message = ''
}
},

async fetch_tyres(){
const res = await axios.get(this.$store.state.api_url+'api/fetch-tyres',{

}).then(function(response){
return response.data
}).catch(function(error){
console.log(error)
})

this.tyres = res

},

confirm_purchase(id,size,description,brand,cost){ 
this.show_modal = true
this.selected_tyre_id = id
this.pattern = description
this.brand = brand
this.cost = cost
this.size = size 
},

async confirm_tyre(){
if(!this.first_name){
alert('First name is required')
return
}

if(!this.last_name){
alert('Last name is required')
return
}

if(!this.email){
alert('Email is required')
return
}

if(!this.phone){
alert('Phone is required')
return
}

if(!this.fitting_date){
alert('Date is required')
return
}

if(!this.fitting_time){
alert('Time is required')
return
}

this.text = 'Please wait...'
this.disabled = true

const res = await axios.post(this.$store.state.api_url+'api/confirm-tyre',{
first_name : this.first_name,
last_name : this.last_name,
email : this.email,
phone : this.phone,
tyre_id : this.selected_tyre_id,
fitting_date : this.fitting_date,
fitting_time : this.fitting_time,
brand : this.brand,
size : this.size,
pattern : this.pattern,
cost : this.cost,
qty : this.qty 
}).then(function(response){
return response.data
}).catch(function(error){
console.log(error)
})
Swal.fire({
position: 'top',
icon: 'success',
title: 'Your order has been submitted successfully',
showConfirmButton: false,
timer: 1500
})

this.show_modal = false
this.search_results = ''
this.size = ''
this.first_name,this.last_name,this.email,this.phone = ''
this.text = 'Submit'
this.disabled = false
this.qty = 1

},



},
created(){
this.fetch_tyres()
}
}
</script>


<style scoped>

</style>